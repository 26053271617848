.navy {
  color: #2C5E7B;
}

.dark-navy {
  color: #02304d;
}

.aqua {
  color: #02A1AC;
}

.teal {
  color: #1D828D;
}

.light-teal {
  color: #26C5D0;
}

.purple {
  color: #50247E;
}

.light-purple {
  color: #A88CB8;
}

.off-white-purple {
  color: #DFE5F5;
}

.orange {
  color: #D47404;
}

.dark-orange {
  color: #C25700;
}

.light-orange {
  color: #F5980A;
}

.text-gray {
  color: #49494A;
}

.text-dark-gray {
  color: #58595B;
}

.teal-shadow {
  color: #186C75;
}

.white-shadow {
  color: #E3EEEE;
}

.navy-shadow {
  color: #225471;
}

.orange-shadow {
  color: #946000;
}

.light-blue {
  color: #DFEEEF;
}

.brown {
  color: #998358;
}

.tan {
  color: #F5EBD9;
}

.off-white {
  color: #EAEFF2;
}

.white {
  color: #FFFFFF;
}

.black {
  color: #000000;
}

.red {
  color: #FF0000;
}

.modal-bg {
  color: rgba(0, 0, 0, 0.5);
}

/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 75% 100%;
  src: url("/assets/fonts/open-sans-latin-regular-variable.woff2") format("woff2");
}
.home-hero-container {
  background-image: url("/assets/img/pages/home/home-hero-mobile.fd97b712.jpg");
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 163px 0 48px 0;
  height: 830px;
}
html[data-webp=true] .home-hero-container {
  background-image: url("/assets/img/pages/home/home-hero-mobile.8399cfb4.webp");
}
.home-hero-container .container {
  height: 100%;
}
.home-hero-container .container .row {
  height: 100%;
}
.home-hero-container .container .row .home-hero-inner-content-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.home-hero-container .hero-clipped-box-container {
  background: #1D828D;
  padding: 14px 0;
  margin-bottom: 267px;
  width: 98%;
  height: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home-hero-container .hero-clipped-box-container::after {
  content: "";
  position: absolute;
  top: -5.5px;
  left: 100%;
  width: 19px;
  height: 100%;
  background-color: #02A1AC;
  clip-path: polygon(0 0, 100% 0, 100% 94%, 0 100%);
  transform: skewY(-30deg);
}
.home-hero-container .hero-clipped-box-container h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
}
.home-hero-container .hero-clipped-box-container p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}
.home-hero-container .hero-lower-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.home-hero-container .hero-lower-container h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 75;
  font-size: 28px;
  line-height: 120%;
  margin-bottom: 32px;
}
.home-hero-container .hero-lower-container p {
  margin: 8px 0 32px 0;
}

.home-positive-impact-container {
  background: #2C5E7B;
  padding: 64px 0;
}
.home-positive-impact-container .five-x-container {
  padding: 16px 0;
  border-top: 1px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home-positive-impact-container .five-x-container p {
  font-size: 80px;
  font-weight: 700;
  line-height: 120%;
}
.home-positive-impact-container .box-animation-container {
  margin: 32px 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home-positive-impact-container .box-animation-container p {
  margin: 32px 0;
  font-size: 18px;
  line-height: 140%;
}

.home-interconnect-container {
  padding: 64px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-interconnect-container h1 {
  margin-bottom: 16px;
}
.home-interconnect-container p {
  margin-bottom: 32px;
}
.home-interconnect-container .interconnect-card {
  margin-bottom: 48px;
}
.home-interconnect-container .interconnect-card .animation-box-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-interconnect-container .interconnect-card .animation-box-content-container .logo-container {
  padding-bottom: 16px;
}
.home-interconnect-container .interconnect-card .animation-box-content-container .logo-container img {
  width: 200px;
  height: auto;
}
.home-interconnect-container .interconnect-card .animation-box-content-container .percent-99-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
}
.home-interconnect-container .interconnect-card .animation-box-content-container .percent-99-container p {
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 75;
}
.home-interconnect-container .interconnect-card .animation-box-content-container .percent-99-container p:first-of-type {
  margin-right: 8px;
  font-size: 58px;
  font-weight: 700;
  line-height: 130%;
}
.home-interconnect-container .interconnect-card .animation-box-content-container .pay-0-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-interconnect-container .interconnect-card .animation-box-content-container .pay-0-container p {
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 75;
}
.home-interconnect-container .interconnect-card .animation-box-content-container .pay-0-container p:first-of-type {
  margin-right: 8px;
  font-size: 30px;
  font-weight: 700;
  line-height: 120%;
}
.home-interconnect-container button {
  margin-bottom: 32px;
}
.home-interconnect-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
}

.home-consider-container {
  padding: 64px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1D828D;
}
.home-consider-container .header-copy-container {
  padding-bottom: 32px;
}
.home-consider-container .header-copy-container h1 {
  margin-bottom: 24px;
}
.home-consider-container .header-copy-container p {
  margin: 0;
}
.home-consider-container h2 {
  margin-bottom: 24px;
}
.home-consider-container .consider-card {
  margin: 32px 8px 48px 0;
}
.home-consider-container .consider-card .animation-box-content-container .percent-50-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
}
.home-consider-container .consider-card .animation-box-content-container .percent-50-container p {
  margin: 0;
  font-size: 64px;
  line-height: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 75;
}
.home-consider-container .consider-card .animation-box-content-container .percent-50-container p:first-of-type {
  font-size: 24px;
  line-height: 120%;
  margin-right: 8px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 75;
}
.home-consider-container a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-consider-container .footnote {
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .home-hero-container {
    background-image: url("/assets/img/pages/home/home-hero-tablet.2e99fb40.jpg");
    height: 900px;
  }
  html[data-webp=true] .home-hero-container {
    background-image: url("/assets/img/pages/home/home-hero-tablet.dda84372.webp");
  }
}
@media only screen and (min-width: 992px) {
  .home-hero-container {
    padding: 324px 0 40px 0;
    background-image: url("/assets/img/pages/home/home-hero-desktop.6348aadc.jpg");
    background-position: 50% 0%;
    height: fit-content;
  }
  html[data-webp=true] .home-hero-container {
    background-image: url("/assets/img/pages/home/home-hero-desktop.b3b1f247.webp");
  }
}
@media only screen and (min-width: 992px) and (min-width: 992px) and (max-width: 1200px) {
  .home-hero-container {
    background-position: 58% 0%;
  }
}
@media only screen and (min-width: 992px) {
  .home-hero-container .hero-clipped-box-container {
    padding: 18px 18px 10px 25px;
    margin-bottom: 183px;
    width: 80%;
  }
  .home-hero-container .hero-clipped-box-container::after {
    top: -10px;
    width: 35px;
  }
  .home-hero-container .hero-clipped-box-container h1 {
    font-size: 32px;
  }
  .home-hero-container .hero-clipped-box-container p {
    font-size: 16px;
  }
  .home-hero-container .hero-lower-container {
    margin-top: 0;
  }
  .home-hero-container .hero-lower-container h2 {
    font-size: 35px;
    line-height: 110%;
    margin-bottom: 24px;
  }
  .home-positive-impact-container {
    padding: 72px 0;
  }
  .home-positive-impact-container .five-x-container {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .home-positive-impact-container .five-x-container h2 {
    font-size: 30px;
    line-height: 120%;
  }
  .home-positive-impact-container .five-x-container p {
    margin: 0 20px;
    font-size: 110px;
    line-height: 86px;
  }
  .home-positive-impact-container .box-animation-container {
    height: 88%;
    margin: 52px 12px 32px 0;
  }
  .home-positive-impact-container .box-animation-container.box-2 {
    margin: 52px 0 32px 12px;
  }
  .home-interconnect-container {
    padding: 72px 0;
  }
  .home-interconnect-container .interconnect-body-copy {
    padding-right: 40px;
    padding-bottom: 16px;
  }
  .home-interconnect-container .interconnect-card {
    margin-bottom: 0;
  }
  .home-interconnect-container .interconnect-card .animation-box-content-container .logo-container {
    padding-bottom: 8px;
  }
  .home-interconnect-container .interconnect-card .animation-box-content-container .logo-container img {
    width: 256px;
    height: auto;
  }
  .home-interconnect-container p {
    margin-bottom: 0px;
  }
  .home-interconnect-container a {
    justify-content: flex-start;
  }
  .home-interconnect-container .interconnect-bottom-link {
    justify-content: center;
  }
  .home-consider-container {
    padding: 72px 0;
  }
  .home-consider-container h2 {
    margin-bottom: 24px;
  }
  .home-consider-container p {
    margin-bottom: 16px;
  }
  .home-consider-container .consider-card {
    margin: 0;
  }
  .home-consider-container .consider-card .animation-box-content-container .percent-50-container p {
    font-size: 96px;
    line-height: 100%;
  }
  .home-consider-container .consider-card .animation-box-content-container .percent-50-container p:first-of-type {
    font-size: 36px;
    line-height: 100%;
  }
  .home-consider-container .consider-card .animation-box-content-container h3 {
    font-size: 25px;
    line-height: 100%;
  }
  .home-consider-container a {
    justify-content: flex-start;
  }
}